<template>
  <div class="mt-4">
    <v-card-title>
      Filtro
      <v-spacer></v-spacer>
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Pesquisa"
        single-line
        hide-details
      ></v-text-field>
    </v-card-title>
    <download-excel
      name="programadosAtendidos.xls"
      :data="programacaoAtendido"
      :fields="fields_excel"
    >
      <v-btn color="info" class="w-100 mt-5" style="margin-bottom: 20px">
        <v-icon class="mr-2" left dark> mdi-download </v-icon>
        Baixar Planilha</v-btn
      >
    </download-excel>
    <v-data-table
      :headers="headers"
      :search="search"
      sort-by="servico,data"
      :items="programacaoAtendido"
      :footer-props="{
        itemsPerPageAllText: `Todos (${programacaoAtendido.length})`,
        itemsPerPageOptions: [50, 100, 150, 200, -1],
      }"
    >
      <template v-slot:[`item.data`]="{ item }">
        {{ item.data ? new Date(item.data).toLocaleString() : '-' }}
      </template>
      <template v-slot:[`item.tef_min`]="{ item }">
        {{ seconds2text(item.tef_min) }}
      </template>
      <template v-slot:[`item.tef_media`]="{ item }">
        {{ seconds2text(item.tef_media) }}
      </template>
      <template v-slot:[`item.tef_max`]="{ item }">
        {{ seconds2text(item.tef_max) }}
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'

import { toReal, seconds2text } from '@/mixins/convertion.js'

export default {
  name: 'ListagemProgramadoAtendido',

  mixins: [toReal],

  data() {
    return {
      programacaoAtendido: [],
      search: '',
      dialog: null,
      totalServicos: 0,
      totalCaminhoes: 0,
      relatorioTotal: [],
      headers: [
        { text: 'Data', value: 'data' },
        { text: 'Terminal', value: 'terminal' },
        { text: 'Produto', value: 'produto' },
        { text: 'Sub Prouto', value: 'sub_produto' },
        { text: 'Quantidade programada', value: 'quantidade_previsto' },
        { text: 'Quantidade de agendamento', value: 'quantidade_agendado' },
        { text: 'Quantidade de triagem', value: 'quantidade_atendido' },
        { text: 'Menor Tef', value: 'tef_min' },
        { text: 'Tef Médio', value: 'tef_media' },
        { text: 'Maior Tef', value: 'tef_max' },
      ],
      fields_excel: {
        Data: 'data',
        Terminal: 'terminal',
        Produto: 'produto',
        'Sub Prouto': 'sub_produto',
        'Quantidade programada': 'quantidade_previsto',
        'Quantidade de agendamento': 'quantidade_agendado',
        'Quantidade de triagem': 'quantidade_atendido',
      },
    }
  },
  computed: {
    ...mapState('auth', ['empresaAtual', 'user']),
    ...mapState('programacaoAtendidos', ['params']),

    relatorioTotalTable() {
      return this.relatorioTotalMounted()
    },
  },
  methods: {
    ...mapActions('patio', ['getProgramacaoAtendidos']),
    ...mapActions('template', ['errorMessage']),

    async getInfo() {
      try {
        const results = await this.getProgramacaoAtendidos({
          ...this.params,
        })
        this.programacaoAtendido = results
      } catch (e) {
        if (e.response.status === 404) {
          this.errorMessage('Página não encontrada')
        }
      }
    },

    relatorioTotalMounted() {
      this.valores.total_caminhoes = this.totalCaminhoes
      this.valores.valor_total = this.totalServicos
      this.relatorioTotal.splice(0, 1, this.valores)

      return this.relatorioTotal
    },
  },

  async created() {
    this.seconds2text = seconds2text
  },
}
</script>
