<template>
  <v-container fluid>
    <BaseBreadcrumb
      :title="page.title"
      :icon="page.icon"
      :breadcrumbs="breadcrumbs"
    />
    <v-row>
      <v-col cols="12" sm="12">
        <programado-atendido-list-filtro @search="handleGetServico" />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" sm="12">
        <BaseCard heading="Serviços">
          <listagem-programado-atendido
            ref="listagem"
          ></listagem-programado-atendido>
        </BaseCard>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import programadoAtendidoListFiltro from '@/components/patio/ProgramadoAtendido/ProgramadoAtendidoListFiltro.vue'
import ListagemProgramadoAtendido from '@/components/patio/ProgramadoAtendido/Listagem.vue'
import { toReal, parseDatetime } from '@/mixins/convertion.js'
export default {
  name: 'Servico',

  components: {
    ListagemProgramadoAtendido,
    programadoAtendidoListFiltro,
  },
  mixins: [toReal, parseDatetime],
  data: function () {
    return {
      tab: 'programadoAtendido',
      page: {
        title: 'Relatório de Programado X Atendido',
      },
      breadcrumbs: [
        {
          text: 'Relatório',
          disabled: false,
        },
        {
          text: 'Programado X Atendidos',
          disabled: false,
        },
      ],
    }
  },
  methods: {
    async handleGetServico() {
      await this.$refs['listagem'].getInfo()
    },
  },
}
</script>
