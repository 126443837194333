var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mt-4"},[_c('v-card-title',[_vm._v(" Filtro "),_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Pesquisa","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('download-excel',{attrs:{"name":"programadosAtendidos.xls","data":_vm.programacaoAtendido,"fields":_vm.fields_excel}},[_c('v-btn',{staticClass:"w-100 mt-5",staticStyle:{"margin-bottom":"20px"},attrs:{"color":"info"}},[_c('v-icon',{staticClass:"mr-2",attrs:{"left":"","dark":""}},[_vm._v(" mdi-download ")]),_vm._v(" Baixar Planilha")],1)],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"search":_vm.search,"sort-by":"servico,data","items":_vm.programacaoAtendido,"footer-props":{
      itemsPerPageAllText: ("Todos (" + (_vm.programacaoAtendido.length) + ")"),
      itemsPerPageOptions: [50, 100, 150, 200, -1],
    }},scopedSlots:_vm._u([{key:"item.data",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(item.data ? new Date(item.data).toLocaleString() : '-')+" ")]}},{key:"item.tef_min",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.seconds2text(item.tef_min))+" ")]}},{key:"item.tef_media",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.seconds2text(item.tef_media))+" ")]}},{key:"item.tef_max",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.seconds2text(item.tef_max))+" ")]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }